@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.app{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    font-family: 'Open Sans', sans-serif;

    input{
        padding: 1rem;
        width: 25%;
        font-size: 1.25rem;
        border-radius: 2px;
        border:none;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.075);
        outline: none;
        background: white;
    }

    h1{
        font-size: 1.75rem;
    }

    button{
        padding: 1rem;
        font-size: 1.2rem;
        background: rgba(255,255,255,1);
        border: 2px solid rgba(0,0,0,1);
        outline: none;
        cursor: pointer;
        transition: all ease-in-out 250ms;
        
        &:hover{
            background: black;
            color: white;
        }
    }
}